<template>
  <v-dialog v-model='isOpen' max-width='500px' persistent>
    <v-card class='d-flex flex-column align-start pa-2'>
      <v-card-title class='text-center mb-5'>
        <v-icon class='mr-4 white--text' color='warning' large left>
          mdi-alert
        </v-icon>
        Delete communication
      </v-card-title>
      <v-card-subtitle>
        <b>You are about to delete the following communication:</b>
      </v-card-subtitle>
      <v-col cols='12'>
        <v-simple-table>
          <template #default>
            <thead>
            <tr>
              <th class='text-left'>Topic</th>
              <th class='text-left'>Recipient</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for='(item, index) in itemToDelete' :key='index'>
              <td>{{ item.topic }}</td>
              <td>{{ item.recipient }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-card-actions class='align-self-center'>
        <v-spacer></v-spacer>
        <v-btn
          class='px-14 mx-4 font-weight-bold text-capitalize'
          color='primary'
          large
          outlined
          @click="$emit('closeDialog')"
        >
          Cancel
        </v-btn>
        <v-btn
          :loading='loadingDelete'
          class='px-14 mx-4 font-weight-bold text-capitalize white--text'
          color='warning'
          large
          @click='onDelete'
        >
          Delete
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: 'DeleteCommunicationsDialog',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    itemToDelete: {
      type: Array
    }
  },
  data: () => ({
    loadingDelete: false
  }),
  methods: {
    ...mapActions('communicationStore', ['deleteCommunication', 'getCommunications']),
    async onDelete() {
      this.loadingDelete = true
      try {
        await this.deleteCommunication(this.itemToDelete[0].id)
        await this.getCommunications()
      } catch (e) {
        this.loadingDelete = false
      }
      this.$emit('updateTable')
      this.$emit('closeDialog')
      this.loadingDelete = false
    }
  }
}
</script>

<style scoped>

</style>