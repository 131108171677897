<template>
  <v-row class='d-flex flex-row flex-nowrap align-center justify-center my-5'>
    <v-card
      v-if="isModuleActive('voting')"
      class='d-flex flex-row elevation-3 rounded-lg'
      width='35%'
    >
      <v-icon class='primary white--text px-2 rounded-lg' size='60'>
        mdi-account-group-outline
      </v-icon>
      <v-col class='px-0 d-flex align-center justify-start'>
        <v-card-title class=' font-weight-bold green--text'>
          {{ votingStatistics.employeesRegistered || '0' }}%
        </v-card-title>
        <v-card-title class='font-weight-bold'>Employees Registered to Vote</v-card-title>
      </v-col>
    </v-card>
    <v-card
      v-if="isModuleActive('voting')"
      class='ml-5 mb-0 d-flex flex-row elevation-3 rounded-lg'
      width='35%'
    >
      <v-icon class='primary white--text px-2 rounded-lg' size='60'>mdi-archive-arrow-down-outline</v-icon>
      <v-col class='px-0 d-flex align-center justify-start'>
        <v-card-title class=' font-weight-bold green--text'>{{ votingStatistics.employeesVoted || '0' }}%</v-card-title>
        <v-card-title class='font-weight-bold'>Employees Voted in Selected Election</v-card-title>
      </v-col>
    </v-card>
  </v-row>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'OrganizationStatisticsCards',
  computed: {
    ...mapState({
      votingStatistics: state => state.memberStore.votingStatistics,
      selectedElection: state => state.electionStore.selectedElection
    }),
    ...mapGetters({
      isModuleActive: 'employeeStore/isModuleActive',
      user: 'user'
    })
  }
}
</script>
