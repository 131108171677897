<template>
  <div v-frag>
    <v-card
      v-if="isModuleActive('voting')"
      class='ma-5 elevation-3 rounded-lg pa-5'
      max-width='100%'
    >
      <h3 class='px-6 pb-5 headline font-weight-bold'>
        {{ sections.diagram || 'Employee Registration and Voting by Job Type' }}
      </h3>
      <v-skeleton-loader
        v-if='loadingJobType'
        :loading='loadingJobType'
        type='card'
        width='100%'
      >
      </v-skeleton-loader>
      <div v-if='hasStatistics && !loadingJobType'>
        <GChart
          ref='columnChartObject'
          :data='columnChartData'
          :events='columnChartEventListener'
          :options='columnChartOptions'
          class='mt-4 pt-5'
          type='ColumnChart'
        />
        <span class='d-flex justify-center font-weight-black'>Click on graph to view team member detail</span>
      </div>
      <div
        v-if='!hasStatistics'
        class='py-16'
      >
        <h2 class='secondary--text text-center'>
          There are no election statistics available for this company or voting
          campaign, try selecting another one.
        </h2>
      </div>
      <v-data-table
        v-if='showSectionEmployeesByVoteStatus'
        v-model='selectedEmployeeByVoteStatus'
        :headers='employeesByVoteStatusHeaders'
        :items='employeesByVoteStatus'
        :loading='loadingEmployeeByVoteStatus'
        checkboxColor='primary'
        class='mt-5'
        item-key='id'
        loading-text='Loading empl... Please wait'
        show-select
      >
        <template #top>
          <v-toolbar class='mb-5' flat>
            <v-row class='pt-5 pb-5'>
              <v-col md='6'>
                <v-toolbar-title Class='font-weight-bold mb-4'>
                  {{ sectionEmployeesByVoteTitle }}
                </v-toolbar-title>
              </v-col>
              <v-col align='end' md='6'>
                <v-btn
                  :disabled='selectedEmployeeByVoteStatus.length === 0'
                  class='primary--text text-capitalize mr-6'
                  large
                  outlined
                  @click='openDatePickerDialogFromEmployeesByVoteStatus()'
                >
                  Schedule voting
                </v-btn>
                <v-btn
                  :disabled='selectedEmployeeByVoteStatus.length === 0'
                  class='primary--text text-capitalize'
                  large
                  outlined
                  @click='sendCommunicationFromSelectedEmployeeByVote(selectedEmployeeByVoteStatus)'
                >
                  Send Communication
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-card>
    <div ref='sectionEmployeeByVoteAnchor'></div>
    <v-dialog
      v-model='showDayOffDialog'
      max-width='500px'
    >
      <v-card class='d-flex flex-column align-start pa-2'>
        <v-card-title class='text-center mb-4'>
          <v-icon class='mr-4 white--text' color='success' large left>mdi-alert</v-icon>
          Schedule Employees Time
        </v-card-title>
        <v-card-subtitle>
          <b>Select a time during this election campaign:</b>
        </v-card-subtitle>
        <v-radio-group
          v-model='selectedDate'
          class='ml-6'
        >
          <v-radio
            v-for='date in requestItem.availableDates'
            :key='date'
            :label='date'
            :value='date'
            class='pb-2'
          >
            {{ date }}
          </v-radio>
        </v-radio-group>
        <v-card-actions class='align-self-center'>
          <v-spacer></v-spacer>
          <v-btn
            class='px-14 mx-4 font-weight-bold white--text'
            color='primary'
            large
            outlined
            @click='closeRequestModal'
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled='!selectedDate'
            :loading='loadingSaveSchedule'
            class='px-14 mx-4 font-weight-bold white--text'
            color='primary'
            large
            @click='applyForScheduleVoting'
          >
            Submit
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialog-communication
      @clearSelected='closeRequestModal'
    />
    <v-dialog
      v-model='datePicker'
      max-width='500px'
    >
      <v-card class='d-flex flex-column align-start pa-2'>
        <v-card-title class='text-center mb-4'>
          <v-icon class='mr-4 white--text' color='success' large left>mdi-alert</v-icon>
          Schedule Time
        </v-card-title>
        <v-card-subtitle>
          <b>Select a time:</b>
          <v-menu
            ref='menu5'
            v-model='menu5'
            :close-on-content-click='false'
            max-width='290px'
            min-width='auto'
            offset-y
            transition='scale-transition'
          >
            <template #activator={on,attrs}>
              <v-text-field
                v-bind='attrs'
                v-on='on'
                v-model='datePic'
                dense
                outlined
              ></v-text-field>
            </template>
            <v-date-picker
              v-model='datePic'
              no-title
              @input='menu5 = false'
            ></v-date-picker>
          </v-menu>
        </v-card-subtitle>

        <v-card-actions class='align-self-center'>
          <v-spacer></v-spacer>
          <v-btn
            class='px-14 mx-4 font-weight-bold white--text'
            color='primary'
            large
            outlined
            @click='closeRequestModal'
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled='!datePic'
            class='px-14 mx-4 font-weight-bold white--text'
            color='primary'
            large
            @click='requestReg(datePic)'
          >
            Submit
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { GChart } from 'vue-google-charts'
import DialogCommunication from '../../communication/components/DialogCommunication'
import employee from '../../../services/employee'

const ChartsEnum = { 'columns': 1, 'circle': 2 }
Object.freeze(ChartsEnum)

export default {
  name: 'RegistrationAndVotingByJobType',
  components: {
    DialogCommunication,
    GChart
  },
  data() {
    return {
      loadingJobType: true,
      hasStatistics: true,
      columnChartData: [
        [
          'Company Role',
          'Registered & Voted',
          'Registered & Has Not Voted',
          'Not Registered',
          'Ineligible'
        ]
      ],
      columnChart: [],
      columnChartOptions: {
        height: 305,
        width: '100%',
        chart: {
          title: 'Employee Registration and Voting by Job Type',
          subtitle:
            'Registered & Voted, Registered & Has Not Voted, Not Registered, and Ineligible'
        },
        legend: {
          position: 'bottom',
          width: '100%',
          alignment: 'start'
        },
        animation: {
          startup: true,
          duration: 1500,
          easing: 'linear'
        },
        bar: {
          groupWidth: 100
        },
        axisTitlesPosition: 'out',
        chartArea: {
          height: 200,
          width: '80%',
          bottom: 100
        },
        colors: ['mediumblue', 'gold', 'firebrick', 'darkgreen'],
        fontSize: 14,
        fontName: 'roboto',
        hAxis: {
          baselineColor: '#5c8dd7',
          gridlines: {
            color: 'e6e6e6',
            count: 10
          },
          textPosition: 'out'
        },
        vAxis: {
          minValue: 1,
          gridlines: {
            interval: 1
          }
        }
      },
      pieChartData: [
        ['Vote Status', 'Percentage'],
        ['Registered & Voted', 0],
        ['Registered & Has Not Voted', 0],
        ['Not Registered', 0],
        ['Ineligible', 0]
      ],
      selectedEmployeeByVoteStatus: [],
      showSectionEmployeesByVoteStatus: false,
      selectedForSchedulingVoteEmployeeIds: [],
      requestItem: {},
      showDayOffDialog: false,
      datePicker: false,
      menu5: false,
      datePic: '',
      sectionEmployeesByVoteTitle: '',
      loadingEmployeeByVoteStatus: true,
      employeesByVoteStatus: [],
      selectedDate: '',
      loadingSaveSchedule: false,
      employeesByVoteStatusHeaders: [
        { text: 'Name', align: 'start', sortable: true, value: 'name' },
        { text: 'Scheduled Day', value: 'dayScheduledToVote' },
        { text: 'Voted Date', value: 'votedDate' }
      ]
    }
  },
  computed: {
    ...mapState({
      selectedElection: state => state.electionStore.selectedElection,
      sections: state => state.cmsStore.sections,
      votingStatistics: state => state.memberStore.votingStatistics
    }),
    ...mapGetters({
      isModuleActive: 'employeeStore/isModuleActive',
      user: 'user'
    }),
    columnChartEventListener() {
      return {
        'select': () => {
          this.onChartSelectionChanged(ChartsEnum.columns)
        }
      }
    }
  },
  watch: {
    selectedElection: {
      async handler() {
        if (this.selectedElection?.id)
          await this.getStatisticsVoting(this.selectedElection.id)
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      getVotingStatistics: 'memberStore/getVotingStatistics',
      getEmployeesByVoteStatus: 'memberStore/getEmployeesByVoteStatus'
    }),
    async getStatisticsVoting(electionId) {
      this.loadingJobType = true
      try {
        await this.getVotingStatistics(electionId)
        const pieChartHasData = this.votingStatistics.cycleVotingRecord.length
        const columnChartHasData =
          this.votingStatistics.registrationByJobType.length
        if (pieChartHasData && columnChartHasData) {
          this.pieChartData = [
            ['Vote Status', 'Percentage'],
            ['Registered & Voted', 0],
            ['Registered & Has Not Voted', 0],
            ['Not Registered', 0],
            ['Ineligible', 0]
          ]
          this.columnChartData = [
            [
              'Company Role',
              'Registered & Voted',
              'Registered & Has Not Voted',
              'Not Registered',
              'Ineligible'
            ]
          ]

          this.pieChart = this.votingStatistics.cycleVotingRecord
          this.pieChartData[1][1] = this.pieChart[1][1]
          this.pieChartData[2][1] = this.pieChart[0][1]
          this.pieChartData[3][1] = this.pieChart[2][1]
          this.pieChartData[4][1] = this.pieChart[3][1]

          this.columnChart = this.votingStatistics.registrationByJobType
          this.columnChart.forEach((column) => {
            this.columnChartData.push(column)
          })
          this.loadingJobType = false
        } else {
          this.hasStatistics = false
          this.loadingJobType = false
        }
      } catch (e) {
        this.loadingJobType = false
      }
    },
    async onChartSelectionChanged(chartsEnum) {
      // clear selected on any content change
      this.selectedEmployeeByVoteStatus = []
      if (chartsEnum === ChartsEnum.columns) {
        const selected = this.$refs.columnChartObject.chartObject.getSelection()
        const isDeselected = selected.length === 0
        if (isDeselected) {
          this.showSectionEmployeesByVoteStatus = false
          return
        }
        const selectedJobType = this.votingStatistics.registrationByJobType[selected[0].row][0]
        const selectedVoteCategory = this.pieChartData[selected[0].column][0]
        this.sectionEmployeesByVoteTitle = `${ selectedJobType } - ${ selectedVoteCategory }`
        this.loadingEmployeeByVoteStatus = true
        const data = {
          memberId: this.user.profile_id.member_id,
          electionId: this.selectedElection.id,
          selectedJobType: selectedJobType,
          selectedVoteCategory: selectedVoteCategory
        }
        const response = await this.getEmployeesByVoteStatus(data)
        this.loadingEmployeeByVoteStatus = false
        this.showSectionEmployeesByVoteStatus = true
        if (response.length) {
          this.showSectionEmployeesByVoteStatus = true
          this.employeesByVoteStatus = response
          this.scrollToSectionEmployeeByVote()
        } else {
          this.employeesByVoteStatus = []
        }
      } else if (chartsEnum === ChartsEnum.circle) {
        const selected = this.$refs.pieChartObject.chartObject.getSelection()
        const isDeselected = selected.length === 0
        if (isDeselected) {
          this.showSectionEmployeesByVoteStatus = false
          return
        }
        const headerOffset = 1
        const sectionName = this.pieChartData[selected[0].row + headerOffset][0]
        this.sectionEmployeesByVoteTitle = `${ sectionName }`
        this.loadingEmployeeByVoteStatus = true
        const data = {
          memberId: this.user.profile_id.member_id,
          electionId: this.selectedElection.id,
          selectedJobType: 'null',
          selectedVoteCategory: sectionName
        }
        const response = await this.getEmployeesByVoteStatus(data)
        this.loadingEmployeeByVoteStatus = false
        if (response.length) {
          this.showSectionEmployeesByVoteStatus = true
          this.employeesByVoteStatus = response
          this.scrollToSectionEmployeeByVote()
        } else {
          this.showSectionEmployeesByVoteStatus = false
        }
      }
    },
    scrollToSectionEmployeeByVote() {
      if (this.user.id_rol !== 4 && this.user.id_rol !== 5) {
        this.$smoothScroll({
          scrollTo: this.$refs.sectionEmployeeByVoteAnchor,
          duration: 1000,
          offset: -90
        })
      }
    },
    openDatePickerDialogFromEmployeesByVoteStatus() {
      let selectedForCommIds = []
      this.selectedEmployeeByVoteStatus.forEach((selected) => {
        selectedForCommIds.push(selected.id)
      })
      this.openDatePickerDialog(selectedForCommIds)
    },
    openDatePickerDialog(employeeIds) {
      this.selectedForSchedulingVoteEmployeeIds = employeeIds
      if (this.selectedForSchedulingVoteEmployeeIds.length === 0) {
        alert('You must select an employee')
        return
      }
      const array = this.getDateArray(
        this.selectedElection.start,
        this.selectedElection.end,
        this.selectedElection.earlyVoteStartDate,
        this.selectedElection.earlyVoteEndDate,
        this.selectedElection.voteDate
      )
      this.requestItem = { ...this.selectedElection, availableDates: array }
      this.showDayOffDialog = true
    },
    sendCommunicationFromSelectedEmployeeByVote(ids) {
      let selectedForCommIds = []
      ids.forEach((selected) => {
        selectedForCommIds.push(selected.id)
      })
      if (selectedForCommIds.length === 0) {
        alert('You must select an employee')
        return
      }
      this.$store.dispatch('crud', {
        dialog: true,
        title: 'New Communication',
        id: null,
        data: {
          type_send: 2,
          employees: selectedForCommIds,
          overviewEmployee: true,
          sendNow: true
        }
      })
    },
    getDateArray(start, end, earlyStart, earlyEnd, voteDate) {
      let dateArray = []
      if (earlyStart && earlyEnd) {
        let dates = this.getDaysArray(earlyStart, earlyEnd)
          .map(el => el.toISOString()
            .slice(0, 10))
        dateArray = [...dates]
      }
      if (voteDate) dateArray.push(new Date(voteDate).toISOString()
        .slice(0, 10))
      if (!dateArray) {
        const dates = this.getDaysArray(start, end)
          .map(el => el.toISOString()
            .slice(0, 10))
        dateArray = [...dates]
      }
      return Array.from(new Set(dateArray.filter(el => new Date(new Date(el).setMilliseconds(6 * 3600 * 1000)) >= Date.now())
        .sort()))
    },
    getDaysArray(start, end) {
      start = new Date(start)
      end = new Date(end)
      let arr = []
      for (let dt = start; dt <= end; dt.setDate(dt.getDate() + 1)) {
        arr.push(new Date(dt))
      }
      return arr
    },
    closeRequestModal() {
      this.showDayOffDialog = false
      this.requestItem = {}
      this.selectedDate = ''
      this.selectedEmployeeByVoteStatus = []
      this.datePicker = false
    },
    async applyForScheduleVoting() {
      try {
        this.loadingSaveSchedule = true
        for (const item of this.selectedForSchedulingVoteEmployeeIds) {
          await this.submitVotingRequest(item)
        }
      } catch (e) {
        this.loadingSaveSchedule = false
      }
      this.closeRequestModal()
      this.loadingSaveSchedule = false
    },
    async submitVotingRequest(employeeId) {
      const userId = this.$store.getters.user.id
      const data = {
        electionId: this.requestItem.id,
        employeeId: employeeId,
        voteDate: this.selectedDate,
        user_who_approved_id: userId,
        is_approved: true
      }
      try {
        await employee.submitVotingRequest(data)
        this.requests = []
      } catch (err) {
        console.log(err)
      }
    },
    async requestReg(item) {
      const employeeIds = this.selectedForCommIds
      let state = true
      try {
        for (const employeeId of employeeIds) {
          await employee.addRegistreRequest({ employeeId, item, state })
        }
      } catch (err) {
        console.log(err)
        this.closeRequestModal()
      }
      this.closeRequestModal()
    }
  }
}
</script>

<style scoped lang='scss'>

::v-deep .theme--light.v-skeleton-loader .v-skeleton-loader__image,
::v-deep .theme--light.v-skeleton-loader .v-skeleton-loader__heading {
  background: #5b8dd7;
}

</style>
