<template>
  <div v-frag>
    <Alert/>
    <v-card class='px-8 py-5 ma-5 mt-0 rounded-lg' elevation='3' max-width='100%'>
      <v-card-title class='font-weight-bold justify-start headline'>
        {{ sections.organization_status || 'My Organization Status' }}
      </v-card-title>
      <v-data-table
        ref='dataTable'
        v-model='tableSelected'
        :expanded='expanded'
        :headers='organizationStatisticsHeaders'
        :items='subordinates'
        :loading='loadingTable'
        disable-sort
        checkboxColor='primary'
        class='myTable'
        hide-default-footer
        item-key='id'
        show-expand
        show-select
      >
        <template #top>
          <v-row class='justify-center'>
            <v-btn
              v-if="isModuleActive('voting')"
              class='primary--text text-capitalize mr-6'
              large
              outlined
              @click='openScheduleVotingDialog'
            >
              Schedule voting
            </v-btn>
            <v-btn
              class='primary--text text-capitalize'
              large
              outlined
              @click='openSendCommunicationDialog'
            >
              Send Communication
            </v-btn>
          </v-row>
          <v-row>
            <v-switch
              v-model='expandValue'
              :disabled='loadingTable || loadingExpand'
              :label='`${expandValue.toString()}`'
              :loading='loadingExpand'
              class='pl-16'
              false-value='Collapsed'
              inset
              true-value='Expanded'
              @click='expandAllTable'
            ></v-switch>
          </v-row>
        </template>
        <template #item.data-table-expand='data'>
          <v-btn
            v-if="data.item.name.includes('(')"
            :loading='loadingSingleExpand && data.item.id === employeeTableId'
            icon
            @click='populateTreeview(data)'
          >
            <v-icon :class="['v-data-table__expand-icon', { 'v-data-table__expand-icon--active': data.isExpanded }]">
              $expand
            </v-icon>
          </v-btn>
        </template>
        <template #header.dayScheduledToVote={header}>
          <div class='d-flex align-center justify-center'>
            <span>{{ header.text }}</span>
            <v-tooltip max-width='300' top>
              <template #activator='{ on, attrs }'>
                <v-icon
                  v-bind='attrs'
                  v-on='on'
                  class='tooltip-icon ml-2'
                  size='20'
                >
                  mdi-eye
                </v-icon>
              </template>
              <div class='text-center'>If an employee is marked N/A. they do not have a voter guide for this election.
              </div>
            </v-tooltip>
          </div>
        </template>
        <template #item.reg={item}>
          <span class='text-capitalize'>
            {{ item.reg }}
          </span>
        </template>
        <template #item.votedDate={item}>
          <span class='text-capitalize'> {{ item.votedDate }} </span>
        </template>
        <template #item.empReg={item}>
        <span
          :class="{
            'success--text': item.empReg === 100,
            'warning--text': item.empReg < 100 && item.empReg >= 50,
            'error--text': item.empReg < 50,
          }"
        >
          {{ item.empReg }} %
        </span>
        </template>
        <template #item.empVot={item}>
        <span
          :class="{
            'success--text': item.empVot === 100,
            'warning--text': item.empVot < 100 && item.empVot >= 50,
            'error--text': item.empVot < 50,
          }"
        >
          {{ item.empVot }} %
        </span>
        </template>
        <template #expanded-item={headers,item}>
          <td :colspan='headers.length' class='ma-0 td'>
            <v-treeview
              ref='treeview'
              v-model='item.selectedTreeview'
              :items='item.children'
              :open-all='expandTreeview'
              expand-icon='$expand'
              hoverable
              item-key='id'
              selectable
              selected-color='#5b8dd7'
              selection-type='independent'
              transition
            >
              <template #label={item}>
                <div class='rowCustom'>
                  <div class='item'>{{ item.name }}</div>
                  <div class='item'>{{ item.jobTitle }}</div>
                  <div :style="{ paddingLeft: item.reg==='No' && 3+'px' }" class='item'>{{ item.reg }}</div>
                  <div class='item'>{{ item.dayScheduledToVote }}</div>
                  <div class='item'>{{ item.votedDate }}</div>
                  <div
                    :class="{
                  'success--text': item.empReg === 100,
                  'warning--text': item.empReg < 100 && item.empReg >= 50,
                  'error--text': item.empReg < 50,
                  }"
                    class='item'
                  >{{ item.empReg }} %
                  </div>
                  <div
                    :class="{
                  'success--text': item.empVot === 100,
                  'warning--text': item.empVot < 100 && item.empVot >= 50,
                  'error--text': item.empVot < 50,
                  }"
                    class='item'
                  >{{ item.empVot }} %
                  </div>
                </div>
              </template>
            </v-treeview>
          </td>
        </template>
      </v-data-table>
    </v-card>
    <schedule-voting-dialog
      :isOpen='isScheduleVotingDialogOpen'
      :requestItem='requestItem'
      :selectedIds='selectedEmployeesIds'
      @closeDialog='closeDialog'
      @updateTable='getData'
    />
    <dialog-communication
      @clearSelected='closeDialog'
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import ScheduleVotingDialog from './ScheduleVotingDialog'
import DialogCommunication from '../../communication/components/DialogCommunication'
import Alert from '../../../components/Alert'

export default {
  name: 'MyOrganizationStatus',
  components: { Alert, DialogCommunication, ScheduleVotingDialog },
  data() {
    return {
      expandValue: 'Collapsed',
      expandTreeview: false,
      loadingExpand: false,
      loadingSingleExpand: false,
      employeeTableId: -1,
      expanded: [],
      tableSelected: [],
      organizationStatisticsHeaders: [
        { text: 'Name', align: 'start', value: 'name', width: '20%' },
        { text: 'Job Title', value: 'jobTitle' },
        { text: 'Reg', value: 'reg' },
        { text: 'Day Scheduled to Vote', align: 'center', value: 'dayScheduledToVote' },
        { text: 'Day Voted', align: 'center', value: 'votedDate' },
        { text: 'Emp. Reg', align: 'center', value: 'empReg' },
        { text: 'Emp. Vot', align: 'center', value: 'empVot' }
      ],
      loadingTable: false,
      tableIds: [],
      treeviewIds: [],
      selectedEmployeesIds: [],
      isScheduleVotingDialogOpen: false,
      requestItem: {}
    }
  },
  computed: {
    ...mapState({
      selectedElection: s => s.electionStore.selectedElection,
      subordinates: s => s.memberStore.subordinates,
      sections: state => state.cmsStore.sections
    }),
    ...mapGetters('employeeStore', ['isModuleActive'])
  },
  watch: {
    selectedElection: {
      async handler() {
        if (this.selectedElection?.id)
          await this.getData()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('memberStore', ['getSubordinates', 'getSubordinatesView']),
    async getData() {
      try {
        this.loadingTable = true
        await this.getSubordinates(this.selectedElection.id)
        if (this.selectedSubordinateId)
          await this.updateTreeview()
      } catch (e) {
        this.loadingTable = false
      }
      this.loadingTable = false
    },
    async expandAllTable() {
      if (this.expandValue === 'Expanded') {
        this.expandTreeview = false
        this.expanded = []
        this.loadingExpand = true
        const itemsToExpand = []
        for (const el of this.subordinates) {
          await this.populateTreeview({ item: el })
          if (el.name.includes('('))
            itemsToExpand.push(el)
        }
        this.expanded = itemsToExpand
        this.expandTreeview = true
        this.loadingExpand = false
      } else {
        this.expanded = []
        this.expandTreeview = false
      }
    },
    async populateTreeview({ item, expand, isExpanded }) {
      this.employeeTableId = item.id
      this.selectedSubordinateId = item.id
      if (item.name.includes('(') && !isExpanded) {
        if (isExpanded || isExpanded === false)
          this.loadingSingleExpand = true
        const data = {
          electionId: this.selectedElection.id,
          selectedEmployeeId: item.id
        }
        try {
          item.children = await this.getSubordinatesView(data)
        } catch (e) {
          this.loadingSingleExpand = false
        }
      }
      if (isExpanded || isExpanded === false)
        expand(!isExpanded)
      if (isExpanded)
        this.expandValue = 'Collapsed'
      this.loadingSingleExpand = false
    },
    openScheduleVotingDialog() {
      this.compareSelected()
      const array = this.getDateArray(
        this.selectedElection.start,
        this.selectedElection.end,
        this.selectedElection.earlyVoteStartDate,
        this.selectedElection.earlyVoteEndDate,
        this.selectedElection.voteDate,
        this.selectedElection.firstWeekEndDate
      )
      this.requestItem = { ...this.selectedElection, availableDates: array }
      this.selectedEmployeesIds.length === 0 ? alert('You must select an employee') : this.isScheduleVotingDialogOpen = true
    },
    compareSelected() {
      this.selectedEmployeesIds = []
      let treeviewIds = []
      const tableIds = []
      this.tableSelected.forEach(selected => {
        const found = tableIds.findIndex((el) => el === selected)
        if (found === -1)
          tableIds.push(selected.id)
      })
      this.subordinates.forEach(el => {
        if ('selectedTreeview' in el)
          treeviewIds = treeviewIds.concat(el.selectedTreeview)
      })
      this.selectedEmployeesIds = [...treeviewIds, ...tableIds]
    },
    async closeDialog() {
      this.isScheduleVotingDialogOpen = false
      this.tableSelected = []
      this.subordinates.forEach(el => el.selectedTreeview = [])
      this.selectedEmployeesIds = []
    },
    async updateTreeview() {
      const data = {
        electionId: this.selectedElection.id,
        selectedEmployeeId: this.selectedSubordinateId
      }
      for (const el of this.subordinates) {
        if (el.name.includes('(')) {
          el.children = await this.getSubordinatesView(data)
        }
      }
    },
    getDateArray(start, end, earlyStart, earlyEnd, voteDate, firstWeek) {
      let dateArray = []
      if (new Date(Date.now()).getTime() <= new Date(firstWeek.replace(/-/g, '/')).getTime()) {
        let dates = this.getDaysArray(earlyStart, firstWeek).map(el => this.formatDate(el))
        dateArray = [...dates]
      } else {
        if (earlyStart && earlyEnd) {
          let dates = this.getDaysArray(earlyStart, earlyEnd).map(el => this.formatDate(el))
          dateArray = [...dates]
        }
        if (voteDate) dateArray.push(this.formatDate(new Date(voteDate.replace(/-/g, '/'))))
        if (!dateArray) {
          const dates = this.getDaysArray(start, end).map(el => this.formatDate(el))
          dateArray = [...dates]
        }
      }
      return Array.from(new Set(dateArray.filter(el => new Date(new Date(el).setMilliseconds(6 * 3600 * 1000)) >= new Date(Date.now()).setHours(0, 0, 0, 0))))
    },
    getDaysArray(start, end) {
      let startDate = new Date(start.replace(/-/g, '/'))
      startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())
      let endDate = new Date(end.replace(/-/g, '/'))
      endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate())
      let datesArr = []
      for (let dt = startDate; dt <= endDate; dt.setDate(dt.getDate() + 1)) {
        datesArr.push(new Date(dt))
      }
      return datesArr
    },
    formatDate(date) {
      let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date)
      let month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date)
      let day = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(date)
      return `${ month } ${ day }, ${ year }`
    },
    openSendCommunicationDialog() {
      this.compareSelected()
      this.$store.dispatch('crud', {
        dialog: true,
        title: 'New Communication',
        id: null,
        data: {
          type_send: 2,
          sendNow: true,
          employees: this.selectedEmployeesIds,
          viewVotes: true
        }
      })
    }
  }
}
</script>

<style lang='scss'>
.v-treeview-node__root button {
  width: 0;
}
div.v-treeview-node__level {
  width: 10px;
}
.v-application--is-ltr {
  .v-treeview-node__toggle {
    transform: rotate(0deg) !important;
  }
  .v-treeview-node__checkbox {
    margin-left: 25px;
  }
  .v-treeview-node__toggle--open {
    transform: rotate(-180deg) !important;
  }
}
div.v-application--is-ltr .v-treeview-node__content {
  margin-left: 0;
}
div.v-treeview-node__root {
  padding-left: 10px;
}
.td {
  padding-left: 110px !important;
}
.v-treeview-node__level + .v-treeview-node__checkbox {
  margin-left: 15px;
}
.v-application--is-ltr .myTable.v-data-table > .v-data-table__wrapper > table > thead > tr > th > .v-simple-checkbox {
  visibility: hidden;
}
.myTable {
  width: 100%;
  .v-data-table__wrapper {
    width: 100%;
    table {
      width: 1200px !important;
      margin: 0 auto;
    }
  }
}
.item {
  position: absolute;
  top: 15px;
  &:first-child {
    margin-left: 15px;
  }
  &:nth-child(2) {
    left: 278px;
  }
  &:nth-child(3) {
    left: 446px;
  }
  &:nth-child(4) {
    left: 566px;
  }
  &:nth-child(5) {
    left: 792px;
  }
  &:nth-child(6) {
    left: 922px;
  }
  &:nth-child(7) {
    left: 1026px;
  }
}
.tooltip-icon {
  cursor: pointer;
  opacity: 0.5;
  transition: .5s;
  &:hover {
    opacity: 1;
  }
}
</style>
