<template>
  <v-row class='d-flex flex-row flex-nowrap align-center justify-start'>
    <v-col>
      <v-card
        v-if="isModuleActive('voting')"
        class='d-flex flex-row elevation-3 rounded-lg'
      >
        <v-icon class='primary white--text px-2 rounded-lg' size='60'>mdi-inbox-arrow-down</v-icon>
        <v-col class='px-0'>
          <v-card-title class='mb-6 font-weight-bold'>
            {{ votingStatistics.employeesVoted || '0' }} % employees voted
          </v-card-title>
          <v-card-subtitle>
            Company Wide in
            <span class='text-capitalize'>{{ selectedElection.description }}</span>
          </v-card-subtitle>
        </v-col>
      </v-card>
    </v-col>
    <v-col>
      <v-card
        v-if="isModuleActive('volunteering')"
        class='d-flex flex-row ml-4 elevation-3 rounded-lg'
      >
        <v-icon class='primary white--text px-2 rounded-lg' size='60'>mdi-hand-heart-outline</v-icon>
        <v-col class='px-0'>
          <v-card-title class='mb-6 font-weight-bold'>
            <span class='mr-1'>{{ volunteeringStatistics.hoursVolunteered || '0' }}</span>
            hours volunteered
          </v-card-title>
          <v-card-subtitle>Company Wide in {{ volunteeringStatistics.year }}</v-card-subtitle>
        </v-col>
      </v-card>
    </v-col>
    <v-col>
      <v-card
        v-if="isModuleActive('donations')"
        class='d-flex flex-row ml-4 elevation-3 rounded-lg'
      >
        <v-icon class='primary white--text px-2 rounded-lg' size='60'>mdi-currency-usd</v-icon>
        <v-col class='px-0'>
          <v-card-title class='mb-6 font-weight-bold'>
            <span class='mr-1'>${{ formattedAllocationTotal || '0' }}</span>
            donated
          </v-card-title>
          <v-card-subtitle>Company Wide in {{ donationStatistics.allocation.year }}</v-card-subtitle>
        </v-col>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'MyTeamStatisticsCards',
  computed: {
    ...mapState({
      selectedElection: state => state.electionStore.selectedElection,
      votingStatistics: state => state.memberStore.votingStatistics,
      volunteeringStatistics: state => state.memberStore.volunteeringStatistics,
      donationStatistics: state => state.memberStore.donationStatistics
    }),
    ...mapGetters({
      isModuleActive: 'employeeStore/isModuleActive',
      user: 'user'
    }),
    formattedAllocationTotal() {
      return this.donationStatistics.allocation.allocationTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  async created() {
    await Promise.allSettled([
      this.getVolunteeringStatistics(),
      this.getDonationStatistics(this.user.profile_id.member_id)
    ])
  },
  methods: {
    ...mapActions({
      getDonationStatistics: 'memberStore/getDonationStatistics',
      getVolunteeringStatistics: 'memberStore/getVolunteeringStatistics'
    })
  }
}
</script>
