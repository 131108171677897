<template>
  <div v-frag>
    <organization-statistics-cards/>
    <v-card class='elevation-3 rounded-lg px-8 py-5 ma-5 mt-0'>
      <v-card-title class='headline font-weight-bold pt-0'>
        {{ sections.current_election || 'Current Election' }}
      </v-card-title>
      <div class='d-flex flex-column justify-center'>
        <change-election
          :elections='elections'
          :selectedElection='selectedElection'
          :title='sections.change_election_manage_my_team'
          btn-color='success'
          class='mb-5'
        />
        <!--        <campaign-checklist/>-->
      </div>
    </v-card>
    <my-organization-status/>
    <registration-and-voting-by-job-type/>
    <communications-table/>
    <v-card class='elevation-3 rounded-lg ma-5 px-8 py-5'>
      <v-card-title class='headline font-weight-bold'>
        {{ sections.performance || 'My Company\'s Performance' }}
      </v-card-title>
      <v-row>
        <v-col cols='12'>
          <my-team-statistics-cards/>
        </v-col>
        <v-col cols='4'>
          <organization-overview/>
        </v-col>
        <v-col cols='8'>
          <current-election-cycle-voting/>
        </v-col>
      </v-row>
    </v-card>
  </div>
  <!--    <approvals/>-->
  <!--    <scheduled-communication-plan/>-->
</template>

<script>
import ChangeElection from '../../components/change-election/change-election'
import MyTeamStatisticsCards from './components/MyTeamStatisticsCards'
import MyOrganizationStatus from './components/MyOrganizationStatus'
import CurrentElectionCycleVoting from './components/CurrentElectionCycleVoting'
import OrganizationOverview from './components/OrganizationOverview'
import RegistrationAndVotingByJobType from './components/RegistrationAndVotingByJobType'
import CommunicationsTable from './components/CommunicationsTable'
import OrganizationStatisticsCards from './components/OrganizationStatisticsCards'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ManageMyTeam',
  components: {
    OrganizationStatisticsCards,
    CommunicationsTable,
    RegistrationAndVotingByJobType,
    OrganizationOverview,
    MyTeamStatisticsCards,
    ChangeElection,
    MyOrganizationStatus,
    CurrentElectionCycleVoting
  },
  computed: {
    ...mapState({
      elections: s => s.electionStore.userElections,
      selectedElection: s => s.electionStore.selectedElection,
      sections: state => state.cmsStore.sections
    })
  },
  async created() {
    await Promise.allSettled([
      this.getUserElections(),
      this.getModules()
    ])
  },
  methods: {
    ...mapActions({
      getUserElections: 'electionStore/getUserElections',
      getModules: 'employeeStore/getModules',
    })
  }
}
</script>
