<template>
  <v-card width='100%' class='pa-5 elevation-3 rounded-lg d-flex flex-column align-center justify-center'>
    <v-card-title class='headline font-weight-bold'>
      {{ sections.election_cycle || 'Current Election Cycle Voting Record' }}
    </v-card-title>
    <v-card-subtitle class='text-capitalize mb-3'>{{ selectedElection.description }}</v-card-subtitle>
    <v-skeleton-loader
      v-if='loading'
      :loading='loading'
      type='image'
      class='rounded-circle'
      height='300'
      width='300'
    >
    </v-skeleton-loader>
    <GChart
      v-else-if='hasStatistics && !loading'
      :data='pieChartData'
      :options='pieChartOptions'
      type='PieChart'
    />
    <div
      v-if='!hasStatistics'
      class='py-16'
    >
      <h2 class='secondary--text text-center px-8 py-10'>
        There are no election statistics available for this company or
        voting campaign, try selecting another one.
      </h2>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'CurrentElectionCycleVoting',
  data() {
    return {
      loading: false,
      hasStatistics: true,
      pieChartData: [
        ['Vote Status', 'Percentage', {type: 'string', role: 'tooltip'}],
        ['Registered & Voted', 0, ``],
        ['Registered & Has Not Voted', 0, ''],
        ['Not Registered', 0, ''],
        ['Ineligible', 0, '']
      ],
      pieChartOptions: {
        height: 400,
        width: '100%',
        chartArea: {
          width: '100%',
          top: 0,
          left: 0,
          right: 0,
          bottom: 100
        },
        colors: ['mediumblue', 'gold', 'firebrick', 'darkgreen'],
        fontSize: 14,
        fontName: 'roboto',
        pieHole: 0.5,
        legend: {
          position: 'bottom',
          width: '100%'
        },
        pieSliceText: 'none',
        annotations: {
          style: 'none',
        },
        backgroundColor: {}
      }
    }
  },
  computed: {
    ...mapState({
      votingStatistic: s => s.employeeStore.votingStatistic,
      selectedElection: s => s.electionStore.selectedElection,
      sections: state => state.cmsStore.sections
    })
  },
  watch: {
    selectedElection: {
      async handler() {
        if (this.selectedElection?.id)
          await this.getStatistic()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('employeeStore', ['getVotingStatistic']),
    async getStatistic() {
      try {
        this.loading = true
        await this.getVotingStatistic()
        let nullDataCount = 0
        this.votingStatistic.cycleVotingRecord.forEach(el => {
          if (el[1] === 0)
            nullDataCount++
        })
        if (nullDataCount === 4) {
          this.hasStatistics = false
        } else {
          this.hasStatistics = true
          this.pieChartData = [
            ['Vote Status', 'Percentage', {type: 'string', role: 'tooltip'}],
            ['Registered & Voted', 0, ``],
            ['Registered & Has Not Voted', 0, ``],
            ['Not Registered', 0, ``],
            ['Ineligible', 0, ``]
          ]
          this.pieChart = this.votingStatistic.cycleVotingRecord
          this.pieChartData[1][1] = this.pieChart[1][1]  // voted
          this.pieChartData[2][1] = this.pieChart[0][1]  // registrated
          this.pieChartData[3][1] = this.pieChart[2][1]  // not_registrated
          this.pieChartData[4][1] = this.pieChart[3][1]  // inelegible
          const percentageRegisteredAndVoted = this.pieChart[1][1]/(this.pieChart[0][1] + this.pieChart[2][1]) * 100
          const percentageRegisteredAndHasNotVoted = this.pieChart[0][1]/(this.pieChart[0][1] + this.pieChart[2][1]) * 100
          const percentageNotRegistered = this.pieChart[2][1]/(this.pieChart[0][1] + this.pieChart[2][1]) * 100
          const percentageIneligible = this.pieChart[3][1]/(this.pieChart[0][1] + this.pieChart[2][1]  + this.pieChart[3][1]) * 100
          this.pieChartData[1][2] = `Registered & Voted: \n ${this.pieChart[1][1]} (${percentageRegisteredAndVoted.toFixed(2)}%)`
          this.pieChartData[2][2] = `Registered & Has Not Voted: \n ${this.pieChart[0][1]} (${percentageRegisteredAndHasNotVoted.toFixed(2)}%)`
          this.pieChartData[3][2] = `Not Registered: \n ${this.pieChart[2][1]} (${percentageNotRegistered.toFixed(2)}%)`
          this.pieChartData[4][2] = `Ineligible: \n ${this.pieChart[3][1]} (${percentageIneligible.toFixed(2)}%)`
        }
      } catch (e) {
        this.loading = false
      }
      this.loading = false
    }
  }

}
</script>

<style scoped lang='scss'>
::v-deep .theme--light.v-skeleton-loader .v-skeleton-loader__image,
::v-deep .theme--light.v-skeleton-loader .v-skeleton-loader__heading {
  background: #5b8dd7;
  height: 100%;
}
</style>
