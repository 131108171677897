<template>
  <v-card
    :class="{'my-0': !isModuleActive('donations')}"
    class='elevation-3 rounded-lg pa-5'
    width='100%'
  >
    <v-card-title class='headline font-weight-bold mb-5 py-0 d-flex justify-center'>
      {{ sections.organization_overview || 'Organization Overview' }}
    </v-card-title>
    <v-card-subtitle class='d-flex justify-center mb-3'>
      Total Employees:
      <span class='font-weight-bold ml-4'>{{ totalEmployees }}</span>
    </v-card-subtitle>
    <v-data-table
      :headers='organizationHeaders'
      :items='organization'
      :loading='organizationLoader'
      disable-sort
      class='my-table elevation-2'
      hide-default-footer
    >
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'OrganizationOverview',
  data() {
    return {
      organizationHeaders: [
        { text: 'Job Title', align: 'start', value: 'jobTitle', divider: true },
        { text: 'Amount', align: 'start', value: 'amount', divider: true },
        { text: '%', align: 'start', value: 'percentage', divider: true }
      ],
      organization: [],
      totalEmployees: null,
      organizationLoader: true
    }
  },
  async created() {
    await this.getOverviewOrganization()
  },
  computed: {
    ...mapState({
      sections: state => state.cmsStore.sections
    }),
    ...mapGetters({
      isModuleActive: 'employeeStore/isModuleActive',
      user: 'user'
    })
  },
  methods: {
    ...mapActions('memberStore', ['getOrganizationOverview']),
    async getOverviewOrganization() {
      try {
        const { jobTitles, totalEmployees } = await this.getOrganizationOverview(this.user.profile_id.member_id)
        this.organization = jobTitles
        this.totalEmployees = totalEmployees
        this.organizationLoader = false
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
