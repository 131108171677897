<template>
  <div v-frag>
    <v-card id='communications' class='rounded-lg ma-5 pa-5 elevation-3'>
      <v-row class='d-flex align-center mx-3'>
        <v-col class='pa-0' cols='12' md='5'>
          <v-card-title class='font-weight-bold headline'>
            {{ sections.communications || 'Communications' }}
          </v-card-title>
        </v-col>
        <v-col cols='12' md='5'>
          <v-text-field
            v-model='search'
            class='rounded-lg 80%'
            dense
            filled
            hide-details
            placeholder='Search'
            prepend-inner-icon='mdi-magnify'
            rounded
          ></v-text-field>
        </v-col>
        <v-col class='pa-0' cols='12' md='2'>
          <v-btn
            block
            class='white--text text-capitalize'
            color='primary'
            height='100%'
            outlined
            @click='openDialog(null)'
          >
            <v-icon>mdi-plus</v-icon>
            Add New
          </v-btn>
        </v-col>
      </v-row>
      <v-col cols='12'>
        <v-tabs v-model='tab'>
          <v-tab>Scheduled</v-tab>
          <v-tab>Sent</v-tab>
        </v-tabs>
      </v-col>
      <div
        v-if='selected.length > 0'
        v-frag
      >
        <v-col cols='12' md='10'>
          <div v-frag>
            <v-btn
              active-class='red-light'
              class='red--text text-capitalize'
              color='red'
              outlined
              @click='openDialogDelete(null)'
            >
              <v-icon color='red'>mdi-close-thick</v-icon>
              <b class='red--text text-capitalize'>Delete</b>
            </v-btn>
          </div>
        </v-col>
      </div>
      <v-data-table
        v-model='selected'
        :footer-props='{
          disablePagination: loading,
          disableItemsPerPage: loading
        }'
        :headers='headers'
        :items='communicationsData.desserts'
        :loading='loading'
        :options.sync='options'
        :server-items-length='totalCommunications'
        :single-select='false'
        checkboxColor='primary'
        elevation='0'
        item-key='id'
      >
        <template #item.recipient={item}>
          <v-btn
            color='primary'
            elevation='0'
            small
            @click='detailRecipient(item.id)'
          >
            Detail
          </v-btn>
        </template>
        <template #item.id={item}>
          <v-icon
            class='mr-2 my-1 pa-2 primary white--text rounded-sm'
            dense
            @click='openDialog(item.id)'
          >
            mdi-pencil
          </v-icon>
          <v-icon
            class='mr-2 my-1 pa-2 red white--text rounded-sm'
            dense
            @click='openDialogDelete(item)'
          >
            mdi-close-thick
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <delete-communications-dialog
      :isOpen='isDeleteDialogOpen'
      :itemToDelete='itemToDelete'
      @closeDialog='closeDeleteDialog'
      @updateTable='initialLoad'
    />
    <detail-recipient/>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import DeleteCommunicationsDialog from './DeleteCommunicationsDialog'
import DetailRecipient from '../../communication/components/DetailRecipient'

export default {
  name: 'CommunicationsTable',
  components: { DetailRecipient, DeleteCommunicationsDialog },
  data() {
    return {
      search: '',
      tab: 0,
      selected: [],
      headers: [
        { text: 'Topic', align: 'start', value: 'topic' },
        { text: 'Message', align: 'start', value: 'text_mail' },
        { text: 'Scheduled Date', align: 'start', value: 'date' },
        { text: 'Recipient', align: 'start', sortable: false, value: 'recipient' },
        { text: 'type', align: 'start', value: 'type_id' },
        { text: 'From module', align: 'start', sortable: false, value: 'module' },
        { text: 'Action', align: 'start', value: 'id' }
      ],
      loading: false,
      options: {},
      totalCommunications: null,
      itemToDelete: null,
      isDeleteDialogOpen: false,
      searchTimer: null
    }
  },
  computed: {
    ...mapState({
      communicationsData: state => state.communicationStore.communicationsData,
      sections: state => state.cmsStore.sections
    }),
    ...mapGetters(['crud'])
  },
  watch: {
    options: {
      async handler() {
        await this.initialLoad()
      },
      deep: true
    },
    search() {
      clearTimeout(this.searchTimer)
      this.searchTimer = setTimeout(async () => await this.initialLoad(), 1000)
    },
    tab: {
      async handler(current) {
        if (current === 1) {
          this.headers.splice(6, 1)
        } else {
          this.headers.push({
            text: 'Action',
            align: 'start',
            sortable: true,
            value: 'id'
          })
        }
        await this.initialLoad()
      },
      deep: true
    },
    crud: {
      async handler() {
        if (!this.crud.dialog)
          await this.initialLoad()
      },
      deep: true
    }
  },
  created() {
    if (this.$route.hash) {
      const el = document.querySelector(this.$route.hash)
      if (el) this.$smoothScroll({
        scrollTo: el,
        duration: 1000,
        offset: -75
      })
    }
  },
  methods: {
    ...mapActions('communicationStore', ['getCommunications']),
    async initialLoad() {
      this.loading = true
      this.options.type = this.tab
      this.options.search = this.search || null
      try {
        await this.getCommunications(this.options)
        this.totalCommunications = this.communicationsData.totalDesserts
      } catch (e) {
        this.loading = false
      }
      this.loading = false
    },
    openDialog(id = null) {
      // type_send 1 : member , 2 : business
      // type 1 : new , 2 : template
      let sendNow = true
      if (this.tab === 0 && id)
        sendNow = false
      let text = id == null ? 'New Communication' : 'Edit Communication'
      this.$store.dispatch('crud', {
        dialog: true,
        title: text,
        id: id,
        data: {
          type_send: 2,
          sendNow: sendNow,
          viewVotes: true
        }
      })
    },
    openDialogDelete(item) {
      this.itemToDelete = [item]
      this.isDeleteDialogOpen = true
    },
    detailRecipient(id) {
      this.$store.dispatch('crudTwo', {
        dialog: true,
        title: 'Recipient list',
        id: id,
        data: {
          type: 1
        }
      })
    },
    closeDeleteDialog() {
      this.itemToDelete = []
      this.isDeleteDialogOpen = false
    }
  }
}
</script>
